// Modified from https://github.com/carbon-design-system/gatsby-theme-carbon/blob/ed2df93443b3aa9230e1162e6759c61fab90cca5/packages/gatsby-theme-carbon/src/components/Switcher/Switcher.module.scss

@import 'carbon-components/scss/globals/scss/_vars';
@import 'carbon-components/scss/globals/scss/_typography';

.nav {
  color: $inverse-01;
  width: 12rem;
  background-color: $ui-05;
  border-left: 1px solid $inverse-02;
  border-bottom: 1px solid $inverse-02;
  box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.2);
  overflow-y: hidden;
}

.nav li {
  &:first-of-type {
    margin-top: $spacing-05;
  }
  &:last-of-type {
    margin-bottom: $spacing-05;
  }
}

.divider {
  margin: 32px 1rem 8px 1rem;
  color: $carbon--gray-70;
  padding-bottom: 4px;
  border-bottom: 1px solid $carbon--gray-70;
}

.divider span {
  @include carbon--type-style('caption-01');
  color: $carbon--gray-30;
}

.text {
  padding: 6px 1rem;
  @include carbon--type-style('heading-01');
  color: $carbon--gray-10;
}

.link {
  padding: 6px 1rem;
  @include carbon--type-style('heading-01');
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: $carbon--gray-30;

  &:hover {
    background: #353535;
    color: $carbon--gray-10;
  }

  &:focus {
    outline: 2px solid $inverse-01;
    outline-offset: -2px;
  }
}

.link--disabled {
  padding: 6px $spacing-05;
  @include carbon--type-style('heading-01');
  display: block;
  color: $carbon--gray-60;
}
