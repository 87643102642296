@import 'easings.scss';
@import 'carbon-components/scss/globals/scss/_vars';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/elements/scss/import-once/import-once';
@import 'carbon-components/scss/globals/scss/_helper-mixins';
@import '../mixin.scss';

$sider-width: 300px;

.sider {
  position: fixed;
  top: 3rem;
  width: $sider-width;
  left: 0;
  bottom: 0;

  overflow-y: auto;
  padding-left: 0rem;
  background-color: $ui-01;

  z-index: 7999;

  :global {
    .bx--accordion__content {
      padding: 0;
    }

    .bx--tree {
      font-size: 14px;
    }
    .bx--label {
      padding-left: 1rem;
    }
    .bx--tree .bx--tree-leaf-node {
      padding-left: 1.5rem;
    }
    @include for-size(phone-up) {
      .bx--label {
        display: none;
      }
    }
  }

  transition: opacity 0.3s $ease-out-cubic, transform 0.3s $ease-out-cubic;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50px);

  @include for-size(phone) {
    width: 100%;
    padding: 0;
    transform-origin: center top;
    transform: scaleY(0.8);
  }
}


.sider.isExpanded {
  opacity: 1;
  transform: none;
  pointer-events: all;
}

.skeleton {
  padding: 2rem;

  @include for-size(phone-and-tablet) {
    padding: 1rem;
  }
}

.content {
  margin-top: 3rem;
}

@include for-size(phone-up) {
  .content.isExpanded {
    margin-left: $sider-width + 10px;
  }
}
