.tag {
  font-size: 0.8rem;
  line-height: 1rem;
  border-radius: 0.6rem;
  padding: 0.1rem 0.6rem;
  background: #ddd;
  vertical-align: middle;
}

.tag.isClickable {
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
}
