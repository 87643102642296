.content {
  p {
    font-size: 1em;
  }

  ul,
  ol {
    margin-bottom: 1.2em;
  }

  ul {
    margin-left: 0.3em;
    list-style: none;
  }

  ol {
    list-style: decimal;
    margin-left: 1.9em;
  }

  li ul,
  li ol {
    margin-bottom: 0.8em;
    margin-left: 2em;
  }

  li ul {
    list-style: circle;
  }

  ul:first-child {
    padding-top: 0;
  }
  li {
    padding-top: 0.4em;
  }
}

.accordion {
  :global {
    .bx--accordion__content {
      padding-right: 0;
    }
  }
}

.tableView {
  :global {
    .ms-DetailsHeader {
      padding-top: 0px;
    }
  }
}