@import 'open-color/open-color.scss';

.item {
  cursor: pointer;
  background: linear-gradient(rgba($oc-red-8, 0.5), rgba($oc-red-8, 0.3));

  :global(.bx--header__menu-item) {
    &:hover {
      background: $oc-blue-9;
    }
  }
}
